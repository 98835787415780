<template>
  <el-dialog
    top="10vh"

    class="el-dialog-cus"
    center
    :visible="visible"
    append-to-body
    :close-on-click-modal="true"
  >
    <a
      class="pop-img-box"
      :href="link"
      target="_blank"
      :style="{
        backgroundImage: 'url(' + img + ')',
        
        backgroundSize: '100% 100%'
      }"
    >
      <el-link class="custon-close" @click.stop.prevent="cancel"><i class="el-icon-close"></i></el-link>
    </a>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    img: String,
    link: String
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-dialog {
  margin-bottom: 0px !important;
  background: transparent !important;
}
</style>
