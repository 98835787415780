var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content-box"},[_c('div',{staticClass:"info-banner",style:({
        backgroundImage: 'url(' + _vm.bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      })},[_c('head-main',{staticClass:"head-white no-fixed"}),_c('div',{staticClass:"custon-bread"}),_c('div',{staticClass:"city-introduce"},[_c('h5',{staticClass:"city-tits"},[_vm._v(_vm._s(_vm.pageParentTile))]),_c('p',{staticClass:"introduce-con"},[_vm._v(_vm._s(_vm.pageRemark))])])],1),(_vm.infoList.length > 0)?_c('div',{staticClass:"city-contnt-one"},[_c('div',{staticClass:"city-con-width"},[_vm._m(0),(_vm.infoList.length > 0)?_c('div',{staticClass:"city-zx-box"},[_c('div',{staticClass:"city-zx-l"},[_c('span',{staticClass:"city-zx-img",style:({
                backgroundImage: 'url(' + _vm.infoList[0].contentImg + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '100% 100%'
              })}),_c('div',{staticClass:"dynamic-box"},[_c('div',{staticClass:"dynamic-time"},[_c('span',{staticClass:"time-year"},[_vm._v(_vm._s(_vm.infoList[0].createTime.split('-')[0])+"-"+_vm._s(_vm.infoList[0].createTime.split('-')[1].split(' ')[0])+" ")]),_c('span',{staticClass:"time-date"},[_vm._v(_vm._s(_vm._f("getDate")(_vm.infoList[0].createTime)))])]),_c('div',{staticClass:"dynamic-con"},[_c('h5',{on:{"click":function($event){return _vm.toContent(_vm.infoList[0])}}},[_vm._v(_vm._s(_vm.infoList[0].contentName))]),_c('p',[_vm._v(_vm._s(_vm.infoList[0].extInfo))])])])]),_c('div',{staticClass:"city-zx-r"},[_c('ul',{staticClass:"dynamic-list"},[_vm._l((_vm.infoList),function(item,index){return [(index !== 0 && index < 4)?_c('li',{key:item.id},[_c('div',{staticClass:"dynamic-box"},[_c('div',{staticClass:"dynamic-time"},[_c('span',{staticClass:"time-year"},[_vm._v(_vm._s(item.createTime.split('-')[0])+"-"+_vm._s(_vm.infoList[0].createTime.split('-')[1].split(' ')[0])+" ")]),_c('span',{staticClass:"time-date"},[_vm._v(_vm._s(_vm._f("getDate")(item.createTime)))])]),_c('div',{staticClass:"dynamic-con"},[_c('h5',{on:{"click":function($event){return _vm.toContent(item)}}},[_vm._v(_vm._s(item.contentName))]),_c('p',[_vm._v(_vm._s(item.extInfo))])])])]):_vm._e()]})],2)])]):_vm._e(),_c('div',{staticClass:"btn-box"},[(_vm.infoList.length > 0)?_c('el-button',{staticClass:"more-btn",on:{"click":_vm.toMoreInfo}},[_vm._v("查看更多")]):_vm._e()],1)])]):_vm._e(),(_vm.projectList.length > 0)?_c('div',{staticClass:"city-contnt-two"},[_c('div',{staticClass:"city-con-width"},[_c('div',{staticClass:"city-system"},[_vm._m(1),_c('div',{staticClass:"img-info-box"},[_c('span',{staticClass:"system-img",style:({
                backgroundImage: 'url(' + (_vm.projectList[_vm.projectActiveIndex].contentImg || _vm.empty) + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '100% 100%'
              })}),_c('div',{staticClass:"img-info-txt"},[_c('p',[_vm._v("项目名称："+_vm._s(_vm.projectList[_vm.projectActiveIndex].contentName))]),_c('p',[_vm._v("非遗类型："+_vm._s(_vm.projectList[_vm.projectActiveIndex].fintangibleType))]),_c('p',[_vm._v("所在地区："+_vm._s(_vm.projectList[_vm.projectActiveIndex].provinceCityCounty))]),_c('p',[_vm._v("申报单位："+_vm._s(_vm.projectList[_vm.projectActiveIndex].extInfo))]),_c('el-button',{staticClass:"view-more",on:{"click":function($event){return _vm.toContent(_vm.projectList[_vm.projectActiveIndex])}}},[_vm._v("查看详情")])],1)])]),_c('div',{staticClass:"system-item"},[_c('el-carousel',{ref:"carousel",staticClass:"custon-carousel",attrs:{"arrow":"never","height":"286px"},on:{"change":_vm.handleChange}},_vm._l((Math.ceil(_vm.projectList.length / 4)),function(i){return _c('el-carousel-item',{key:i},[_c('ul',{staticClass:"imgList"},[_vm._l((4),function(j){return [(_vm.projectList[(i - 1) * 4 + (j - 1)])?_c('li',{key:j,class:{ active: _vm.projectActiveIndex === (i - 1) * 4 + (j - 1) },style:({
                      backgroundImage: 'url(' + (_vm.projectList[(i - 1) * 4 + (j - 1)].contentImg || _vm.empty) + ')',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '100% 100%'
                    }),on:{"click":function($event){_vm.changeClass((i - 1) * 4 + (j - 1))}}},[_c('div',{staticClass:"fu-div",on:{"click":function($event){_vm.toContent(_vm.projectList[(i - 1) * 4 + (j - 1)])}}},[_vm._v(" "+_vm._s(_vm.projectList[(i - 1) * 4 + (j - 1)].contentName)+" ")])]):_vm._e()]})],2)])}),1)],1)])]):_vm._e(),_c('div',{staticClass:"city-contnt-three"},[_c('div',{staticClass:"city-con-width"},[_vm._m(2),(_vm.inheritorList.length > 0)?_c('div',{staticClass:"person-item"},[_c('el-carousel',{staticClass:"custon-carousel",attrs:{"interval":10000,"arrow":"always","height":"762px"}},_vm._l((Math.ceil(_vm.inheritorList.length / 6)),function(i){return _c('el-carousel-item',{key:i},[_c('ul',{staticClass:"person-ul"},[_vm._l((6),function(j){return [(_vm.inheritorList[(i - 1) * 6 + (j - 1)])?_c('li',{key:j,on:{"click":function($event){_vm.toContent(_vm.inheritorList[(i - 1) * 6 + (j - 1)])}}},[_c('span',{staticClass:"person-img",style:({
                        backgroundImage: 'url(' + (_vm.inheritorList[(i - 1) * 6 + (j - 1)].contentImg || _vm.empty) + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%'
                      })}),_c('h5',{staticClass:"person-name"},[_c('i',{staticClass:"name-i"}),_vm._v(_vm._s(_vm.inheritorList[(i - 1) * 6 + (j - 1)].contentName)+" ")]),_c('p',{staticClass:"person-po"},[_vm._v("非遗项目："+_vm._s(_vm.inheritorList[(i - 1) * 6 + (j - 1)].extInfo))])]):_vm._e()]})],2)])}),1)],1):_vm._e()])]),_c('botto-com',{staticClass:"bottom-white no-fixed"}),_c('pop-image',{ref:"popImage",attrs:{"img":_vm.adImage,"link":_vm.adLink}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',[_c('i',{staticClass:"tit-i1"}),_c('i',{staticClass:"tit-red"},[_vm._v("资讯")]),_vm._v("动态 "),_c('i',{staticClass:"tit-i2"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"system-tit"},[_c('span',{staticClass:"tit-span"},[_c('i',{staticClass:"tit-i1"}),_c('span',[_c('i',{staticClass:"tit-ye"},[_vm._v("非遗")]),_vm._v("项目")]),_c('i',{staticClass:"tit-i2"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custon-line"},[_c('span',[_c('i',{staticClass:"tit-i1"}),_vm._v(" 推荐"),_c('i',{staticClass:"tit-red"},[_vm._v("传承人")]),_c('i',{staticClass:"tit-i2"})])])}]

export { render, staticRenderFns }