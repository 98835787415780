<template>
  <div class="page-content-box">
    <div
      class="info-banner"
      :style="{
        backgroundImage: 'url(' + bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <head-main class="head-white no-fixed"></head-main>
      <div class="custon-bread">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>县市地图</el-breadcrumb-item>
          <el-breadcrumb-item>{{ pageParentTile }}</el-breadcrumb-item>
        </el-breadcrumb> -->
      </div>
      <div class="city-introduce">
        <!-- 南平 -->
        <h5 class="city-tits">{{ pageParentTile }}</h5>
        <p class="introduce-con">{{ pageRemark }}</p>
      </div>
    </div>

    <div class="city-contnt-one" v-if="infoList.length > 0">
      <div class="city-con-width">
        <div class="custon-line">
          <span>
            <i class="tit-i1"></i>
            <i class="tit-red">资讯</i>动态
            <i class="tit-i2"></i>
          </span>
        </div>
        <div class="city-zx-box" v-if="infoList.length > 0">
          <div class="city-zx-l">
            <span
              class="city-zx-img"
              :style="{
                backgroundImage: 'url(' + infoList[0].contentImg + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '100% 100%'
              }"
            ></span>
            <div class="dynamic-box">
              <div class="dynamic-time">
				  <!-- {{infoList[0].createTime}} -->
                <span class="time-year"
                  >{{ infoList[0].createTime.split('-')[0] }}-{{ infoList[0].createTime.split('-')[1].split(' ')[0] }}
                </span>
                <span class="time-date">{{ infoList[0].createTime | getDate}}</span>
              </div>
              <div class="dynamic-con">
                <h5 @click="toContent(infoList[0])">{{ infoList[0].contentName }}</h5>
                <p>{{ infoList[0].extInfo }}</p>
              </div>
            </div>
          </div>
          <div class="city-zx-r">
            <ul class="dynamic-list">
              <template v-for="(item, index) in infoList">
                <li :key="item.id" v-if="index !== 0 && index < 4">
                  <div class="dynamic-box">
                    <div class="dynamic-time">
                      <span class="time-year"
                        >{{ item.createTime.split('-')[0] }}-{{ infoList[0].createTime.split('-')[1].split(' ')[0] }}
                      </span>
                      <span class="time-date">{{ item.createTime | getDate}}</span>
                    </div>
                    <div class="dynamic-con">
                      <h5 @click="toContent(item)">{{ item.contentName }}</h5>
                      <p>{{ item.extInfo }}</p>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="btn-box">
          <el-button v-if="infoList.length > 0" class="more-btn" @click="toMoreInfo">查看更多</el-button>
        </div>
      </div>
    </div>

    <div v-if="projectList.length > 0"  class="city-contnt-two">
      <div class="city-con-width">
        <div class="city-system">
          <div class="system-tit">
            <span class="tit-span">
              <i class="tit-i1"></i>
              <span><i class="tit-ye">非遗</i>项目</span>
              <i class="tit-i2"></i>
            </span>
          </div>
          <div class="img-info-box">
            <span
              class="system-img"
              :style="{
                backgroundImage: 'url(' + (projectList[projectActiveIndex].contentImg || empty) + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '100% 100%'
              }"
            ></span>
            <div class="img-info-txt">
              <p>项目名称：{{ projectList[projectActiveIndex].contentName }}</p>
              <p>非遗类型：{{ projectList[projectActiveIndex].fintangibleType }}</p>
              <!-- <p>非遗级别：{{ projectList[projectActiveIndex].intangibleLevel }}</p> -->
              <p>所在地区：{{ projectList[projectActiveIndex].provinceCityCounty }}</p>
              <p>申报单位：{{ projectList[projectActiveIndex].extInfo }}</p>
              <el-button class="view-more" @click="toContent(projectList[projectActiveIndex])">查看详情</el-button>
            </div>
          </div>
        </div>
        <div class="system-item">
          <el-carousel class="custon-carousel" arrow="never" height="286px" ref="carousel" @change="handleChange">
            <!-- :interval="10000" -->
            <el-carousel-item v-for="i in Math.ceil(projectList.length / 4)" :key="i">
              <ul class="imgList">
                <template v-for="j in 4">
                  <li
                    v-if="projectList[(i - 1) * 4 + (j - 1)]"
                    :key="j"
                    :class="{ active: projectActiveIndex === (i - 1) * 4 + (j - 1) }"
                    @click="changeClass((i - 1) * 4 + (j - 1))"
                    :style="{
                      backgroundImage: 'url(' + (projectList[(i - 1) * 4 + (j - 1)].contentImg || empty) + ')',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '100% 100%'
                    }"
                  >
                    <div class="fu-div" @click="toContent(projectList[(i - 1) * 4 + (j - 1)])">
                      {{ projectList[(i - 1) * 4 + (j - 1)].contentName }}
                    </div>
                  </li>
                </template>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="city-contnt-three">
      <div class="city-con-width">
        <div class="custon-line">
          <span>
            <i class="tit-i1"></i>
            推荐<i class="tit-red">传承人</i>
            <i class="tit-i2"></i>
          </span>
        </div>
        <div v-if="inheritorList.length > 0" class="person-item">
          <el-carousel class="custon-carousel" :interval="10000" arrow="always" height="762px">
            <el-carousel-item v-for="i in Math.ceil(inheritorList.length / 6)" :key="i">
              <ul class="person-ul">
                <template v-for="j in 6">
                  <li
                    v-if="inheritorList[(i - 1) * 6 + (j - 1)]"
                    :key="j"
                    @click="toContent(inheritorList[(i - 1) * 6 + (j - 1)])"
                  >
                    <span
                      class="person-img"
                      :style="{
                        backgroundImage: 'url(' + (inheritorList[(i - 1) * 6 + (j - 1)].contentImg || empty) + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '100% 100%'
                      }"
                    ></span>
                    <h5 class="person-name">
                      <i class="name-i"></i>{{ inheritorList[(i - 1) * 6 + (j - 1)].contentName }}
                    </h5>
                    <p class="person-po">非遗项目：{{ inheritorList[(i - 1) * 6 + (j - 1)].extInfo }}</p>
                  </li>
                </template>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>
    <pop-image ref="popImage" :img="adImage" :link="adLink"></pop-image>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
import popImage from '../../components/popImage'

export default {
  components: {
    HeadMain,
    BottoCom,
    popImage
  },
  data() {
    return {
      empty: require('../../assets/images/empty-pic.png'),
      pageParentTile: '',
      pageRemark: '',
      isActive: 0,
      aindex: 0,
      bannerImg: '', // require('../../assets/images/city-baner.jpg')
      imgSrc: require('../../assets/images/img1.jpg'),
      infoList: [],
      projectList: [],
      projectActiveIndex: 0,
      inheritorList: [],
      adImage: '',
      adLink: ''
    }
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newValue, oldValue) {
        this.getCityIndexData()
      }
    }
  },
  filters:{
	getDate(v){
		var tempDate=new Date(v);
		var date=tempDate.getDate();
		var temp = date/10;
		if(temp<1){
			return "0"+date;
		}else{
			return date;
		}
		
	}  
  },
  methods: {
    handleChange(index) {
      console.log(index)
      this.projectActiveIndex = index * 4 + 1
    },
    changeClass(index) {
      this.projectActiveIndex = index
    },
    getCityIndexData() {
      this.pageParentTile = ''
      this.pageRemark = ''
      this.bannerImg = ''
      this.infoList = []
      this.inheritorList = []
      this.projectList = []
      this.adImage = ''
      this.adLink = ''
      this.axios.get('/api/portal/page/template-data?columnId=' + this.$route.query.id, {}).then(res => {
        let data = res.data
        if (data.cellData && Object.keys(data.cellData).length > 0) {
          this.pageParentTile = data.pageParentTile
          this.pageRemark = data.pageRemark
          let cellData = data.cellData
          let adInfo = data.cellData.adinfo.records
          if (adInfo && adInfo.length > 0) {
            this.bannerImg = adInfo[0].adImg
            if (adInfo[1] && adInfo[1].adImg) {
              this.adImage = adInfo[1].adImg
              this.adLink = adInfo[1].linkUrl
              this.$refs.popImage.open()
            }
          }
          this.infoList = cellData.info.records
          this.inheritorList = cellData.inheritor.records
		  if(cellData.project.records){
          this.projectList = cellData.project.records
		  }
        }
      })
    },
    toMoreInfo() {
      this.$router.push({ path: '/informationNew', query: { id: this.infoList[0].columnId } })
    },
    toContent({ contentUrl, contentId, id }) {
      this.$router.push({ path: contentUrl, query: { id, contentId } })
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'page-body city-body'
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body city-body')
  }
}
</script>
<style lang="scss" scoped>
.dynamic-list {
  padding: 0;
}
.dynamic-list > li {
  display: block;
  border-bottom: none;
  padding-bottom: 0px;
  padding-top: 0px;
}
</style>
